.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 80% !important;
}

/* .datedata {
  width: 80% !important;
} */
